import axios from 'axios'
import { createStore } from 'vuex'
import _ from 'lodash'

export default createStore({

  state: {
    // sourceUrl: 'https://quiz-metiers.local:50115/',
    sourceUrl: 'https://cdm-admin.bart-studio.ch',
    // sourceUrl: 'https://admin.quiz-metiers-transition-energetique.ch',
    // quiz details
    details: [],
    // metiers
    jobs: [],
    // questions
    questions: [],
    // results
    userResponses: [],
    // side image
    // image: ''
    results: []
  },
  mutations: {
    SET_DETAILS (state, details) {
      state.details = details
    },
    SET_QUESTIONS (state, questions) {
      state.questions = questions
    },
    SET_JOBS (state, jobs) {
      state.jobs = jobs
    }
  },
  actions: {
    getDetails ({ commit }) {
      axios
        .get(this.state.sourceUrl + '/wp-json/quiz/v2/details',
          {
            auth: {
              username: 'jerome',
              password: 'gG1t 6T8m tmL9 UzBS fAEO gpgx'
            }
          }
        )
        .then(response => {
          commit('SET_DETAILS', response.data)
          console.log('Details recieved')
          // console.log(this.details)
        })
        .catch(error => {
          console.log('Bad news with the datas')
          console.log(this.state.sourceUrl + '/wp-json/quiz/v2/details')
          console.log(error)
        })
    },
    getQuestions ({ commit }) {
      axios
        .get(this.state.sourceUrl + '/wp-json/quiz/v2/questions',
          {
            auth: {
              username: 'jerome',
              password: 'gG1t 6T8m tmL9 UzBS fAEO gpgx'
            }
          }
        )
        .then(response => {
          commit('SET_QUESTIONS', response.data)
          console.log('Questions recieved')
          console.log(this.questions)
        })
        .catch(error => {
          console.log('Bad news with the questions')
          console.log(error)
        })
    },
    getJobs ({ commit }) {
      axios
        .get(this.state.sourceUrl + '/wp-json/quiz/v2/metiers',
          {
            auth: {
              username: 'jerome',
              password: 'gG1t 6T8m tmL9 UzBS fAEO gpgx'
            }
          }
        )
        .then(response => {
          commit('SET_JOBS', response.data)
          console.log('Jobs recieved')
          // console.log(this.jobs)
        })
        .catch(error => {
          console.log('Bad news with the jobs')
          console.log(error)
        })
    },
    theResults_old () {
      let i = 0
      while (i < this.state.jobs.length) {
        // récupère les questions correspondantes pour le métier
        const validAnswers = Object.values(this.state.jobs[i].questions).map(Number)
        if (validAnswers.length > 0) {
          // les réponses de l'utilisateur
          const userReponsesArr = this.state.userResponses
          console.log('nbre-loop:' + this.state.jobs.length)
          console.log(i)
          console.log('validAnswers' + validAnswers)
          console.log('userReponsesArr' + userReponsesArr)
          // les réponses de l'utilisateur
          const isCorresponding = validAnswers.every(function (value) {
            if (value) {
              return userReponsesArr.includes(value)
            }
          })
          if (isCorresponding === true) {
            this.state.results.push(i)
            console.log('results' + this.state.results)
          }
        }
        i++
        // return this.value
        // pour chaque job dans jobs
        // si toutes les valeurs de job.questions sont dans userResponses
        // this.results[].push(job.index)
      }
      if (this.state.results) {
        return true
      }
    },
    theResults () {
      // pourcentages
      // pour chaque job dans jobs
      // compter toutes les valeurs commune entre userResponses et validanswers
      // compter le nombre de reponses valide
      // générer le pourcentage de bonne reponses
      // si le pourcentage > 50 = créer une entrée dans le tableau results : "identifiant metier -> pourcentage obtenu"
      // modifier l'ordre du tableau
      let i = 0
      const userReponsesArr = this.state.userResponses
      // pour chaque job
      while (i < this.state.jobs.length) {
        const jobValidAnswers = Object.values(this.state.jobs[i].questions).map(Number)
        const jobMandatoryAnswers = Object.values(this.state.jobs[i].mandatory_questions).map(Number)

        if (jobValidAnswers.length > 0) {
          const jobTotalAnswers = jobValidAnswers.length
          const jobResults = _.intersection(jobValidAnswers, userReponsesArr).length

          let jobPercentage = jobResults / jobTotalAnswers * 100
          jobPercentage = Math.round(jobPercentage)

          if (jobPercentage > 0 && jobMandatoryAnswers.length === 0) {
            // Si le job est manuel et que la question X (êtes-vous manuel ?) n'est pas cochée : ne pas enregistrer le métier
            this.state.results.push({ jobIndex: i, percentage: jobPercentage, jobSlug: this.state.jobs[i].slug })
          } else if (jobPercentage > 0 && jobMandatoryAnswers.length > 0 && _.intersection(jobMandatoryAnswers, userReponsesArr).length === jobMandatoryAnswers.length) {
            this.state.results.push({ jobIndex: i, percentage: jobPercentage, jobSlug: this.state.jobs[i].slug })
          }
        }
        i++
      }
      if (this.state.results) {
        // modifier l'ordre
        this.state.results = _.orderBy(this.state.results, ['percentage'], ['desc'])
        return true
      }
      console.log('results')
      console.log(this.state.results)
    }
  },
  getters: {
  },
  modules: {
  }
})

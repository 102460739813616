<template>
  <main class="home page" :style="{'background-image': 'url(' + details.intro_image +')'}">
    <section class="mainContent">
      <sectionHeader/>
      <h1 v-html="details.quiz_title "></h1><!-- {{quiz.title}} -->
      <router-link to="/questions" class="btn">{{ details.start_label }}</router-link>
      <div class="partenaires" v-if="details.logo_partenaires != 0">
          <div class="partenaire" v-for="(partenaire,index) in details.logo_partenaires" :key="index">
            <a :href="partenaire.lien_partenaire" target="_blank"><img :src="partenaire.logo_partenaire.sizes.large" alt=""></a>
          </div>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import quizLogo from '@/components/quizLogo.vue'
import sectionHeader from '@/components/sectionHeader.vue'

export default {
  name: 'Home',
  data () {
    return {
    }
  },
  components: {
    sectionHeader

    // TestAxios
  },
  computed: {
    details () {
      return this.$store.state.details
    }
  }
}
</script>

<style lang="scss">
  .home {
    background: no-repeat center center;
    background-size: cover;

    .section-header {
      @media all and (max-width: 768px) {
        margin-bottom: 23vh;
        font-size: 12px;
      }

      @media all and (max-width: 540px) {
        margin-bottom: inherit;
      }

      .quiz_title {
        border-top: #fff 1px solid;
        width: calc(100% - 20px);
        margin-top: 0;
        color: #fff;
        content: '';

        @media all and (max-width: 540px) {
          margin-top: 14px;
          width: calc(100% - 0px);
        }
      }

      #headericon {
        fill: #fff;
        width: 60px;

        @media all and (max-width: 540px) {
          width: 30px;
        }
      }
    }

    h1 {
      color: #fff;
      max-width: inherit;

       @media all and (max-width: 1024px) {
         br {
           display: none;
         }
       }

       @media all and (max-width: 690px) {
         font-size: 45px;
       }

       @media all and (max-width: 540px) {
         font-size: 35px;
       }
    }

    .btn {
      margin-top: 100px;
      border-color: #fff;
      color: #fff;

      @media all and (max-width: 540px) {
        margin-top: 30px;
        margin-bottom: auto;
      }

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }

    .partenaires {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 100px;

      @media all and (max-width: 540px) {
        margin-bottom: 40px;
      }

      @media all and (max-width: 540px) {
        flex-direction: column;
        align-items: flex-end;
      }

      .partenaire {
        height: 75px;
        margin-left: 50px;

        @media all and (max-width: 768px) {
          height: 50px;
        }

        img {
          height: 100%;
        }
      }
    }
  }

</style>
